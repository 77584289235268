<template>
  <nav v-show="displayOn" class="backdrop-blur bg-white/75">
    <slot></slot>
    <div class="max-w-10xl mx-auto border-b-2 border-b-brand-800">
      <div class="flex h-14">
        <div class="flex z-10 cursor-pointer w-full" @click="goToRoot">
          <img v-if="$store.getters.merchant" class="w-14" :src="$store.getters.merchant?.attributes.avatar_url" />
          <div
            v-if="!emptyMiddle && $store.getters.merchant?.attributes.user_name"
            class="flex items-center h-full w-full sm:w-fit"
          >
            <div class="text-black font-semibold sm:text-black z-10 sm:px-4 px-3 text-lg sm:text-xl tracking-tighter">
              {{ $store.getters.merchant?.attributes.user_name }}
            </div>
          </div>
        </div>

        <div class="relative">
          <div>
            <button
              id="main-menu-button"
              type="button"
              :class="[
                'transtion-colors duration-150 flex text-sm p-4',
                emptyMiddle
                  ? dropdownOpen
                    ? 'bg-transparent text-black'
                    : 'bg-transparent text-black hover:bg-black hover:text-white'
                  : dropdownOpen
                    ? 'sm:bg-transparent sm:text-black bg-transparent text-black'
                    : 'sm:bg-transparent sm:text-black bg-transparent text-black hover:bg-black hover:text-white'
              ]"
              :aria-expanded="dropdownOpen"
              aria-haspopup="true"
              @click="dropdownOpen = !dropdownOpen"
            >
              <span class="sr-only">Open main menu</span>
              <MenuIcon :class="['h-6 w-6 transition-colors duration-150', dropdownOpen ? 'hidden' : 'block']" />
              <XIcon :class="['h-6 w-6 transition-colors duration-150', dropdownOpen ? 'block' : 'hidden']" />
            </button>
          </div>

          <transition
            enter-active-class="transition ease-out duration-200"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
          >
            <div
              v-if="dropdownOpen"
              class="origin-top-right absolute right-0 mt-0 w-56 py-1 z-20 ring-1 ring-black ring-opacity-5 focus:outline-none bg-black text-white"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="main-menu-button"
              tabindex="-1"
            >
              <router-link to="/" class="flex items-center px-4 py-4 text-base hover:underline">
                <HomeIcon class="h-4 w-4 mr-2 shrink-0" />
                <span>Home</span>
              </router-link>
              <a
                v-for="link in links"
                :href="link.attributes.url"
                target="_blank"
                class="flex items-center px-4 py-4 text-base hover:underline border-t"
              >
                <span>{{ link.attributes.primary_text }}</span>
              </a>
              <router-link to="/profile" class="flex items-center px-4 py-4 text-base hover:underline border-t">
                <UserIcon class="h-4 w-4 mr-2 shrink-0" />
                <span class="truncate" v-if="customer?.attributes?.display_user_name">
                  {{ customer.attributes.display_user_name }}
                </span>
                <span v-else-if="customer?.attributes">My Account</span>
                <span v-else>Log in</span>
              </router-link>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { ref, onBeforeUnmount, computed } from 'vue'
import { UserIcon, MenuIcon, XIcon, HomeIcon } from '@heroicons/vue/outline'
import { useStore } from 'vuex'
import mapRelationships from '@/utils/mapRelationships'

export default {
  name: 'MerchantNav',
  components: { UserIcon, MenuIcon, XIcon, HomeIcon },

  props: {
    disableHideOnScroll: { type: Boolean, default: false },
    emptyMiddle: { type: Boolean, default: false }
  },

  setup() {
    const shouldShowHeader = ref(true)
    const dropdownOpen = ref(false)
    const store = useStore()
    const categories = computed(() => store.getters.categories)

    const listener = (e) => {
      shouldShowHeader.value = window.scrollY <= 30 || categories.value.length <= 1
    }

    document.addEventListener('scroll', listener)

    onBeforeUnmount(() => {
      document.removeEventListener('scroll', listener)
    })

    const modules = computed(() =>
      mapRelationships(store.state, store.getters.globalPage?.relationships.page_modules.data).sort(
        (a, b) => a.attributes.order - b.attributes.order
      )
    )
    const module = computed(() => modules.value?.find((x) => x.attributes.type === 'MenuPageModule'))
    const links = computed(() => mapRelationships(store.state, module.value?.relationships.content_elements.data))

    return {
      shouldShowHeader,
      dropdownOpen,
      links
    }
  },

  computed: {
    displayOn() {
      return this.shouldShowHeader || this.disableHideOnScroll
    },
    customer() {
      return this.$store.getters.customer
    }
  },

  methods: {
    goToRoot() {
      let avatarClickUrl = this.$store.getters.merchant?.attributes.avatar_click_url

      if (avatarClickUrl) {
        if (!avatarClickUrl.startsWith('http://') && !avatarClickUrl.startsWith('https://')) {
          avatarClickUrl = 'https://' + avatarClickUrl
        }

        window.open(avatarClickUrl, '_blank')
      } else {
        this.$router.push({ name: 'Home' })
      }
    }
  }
}
</script>
