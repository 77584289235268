<template>
  <!-- TODO(alicja): extract out all those Intls -->
  <div class="bg-gray-brand-100 pb-10" :style="computedColors">
    <Loading v-if="loading" class="mt-16" />

    <!-- navbar -->
    <div class="fixed w-full top-0 z-10">
      <div class="w-full">
        <MerchantNav :disable-hide-on-scroll="true" :empty-middle="true">
          <ProgressPills :pills="progressPills" />
        </MerchantNav>
      </div>
    </div>

    <!-- main body -->
    <div v-if="!loading" class="w-full max-w-5xl mx-auto py-20 h-full">
      <!-- questions -->
      <div
        v-for="question in checkout_questions"
        :key="question.id"
        class="px-4 md:px-0 md:w-1/2 mx-auto mb-8"
        :class="{ 'mt-4': question?.attributes?.question || question?.attributes?.secondary_info }"
      >
        <div class="flex justify-start items-center">
          <div class="text-xl font-bold text-gray-brand-900 tracking-tighter">{{ question?.attributes?.question }}</div>
        </div>
        <div v-if="question?.attributes?.secondary_info" class="flex justify-start items-center">
          <h3 class="text-md text-gray-brand-700 w-full">
            {{ question?.attributes?.secondary_info }}
          </h3>
        </div>
        <QuestionRow :question="question" :editable="true" />
      </div>

      <div v-show="!hasQuestions">
        <div class="md:w-1/2 mx-auto">
          <SecondaryButton @click="goToCart()" class="rounded-full" :size="'md'">
            <template #left-icon>
              <svg
                class="h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 17l-5-5m0 0l5-5m-5 5h12" />
              </svg>
            </template>
            Edit Cart
          </SecondaryButton>
        </div>

        <InfoBanner :bottle="bottle" class="" />

        <div v-if="requiresFulfillmentSlot" id="fulfillmentModule" class="px-2 md:px-0 md:w-1/2 mx-auto mt-6 mb-8">
          <div>
            <div class="flex justify-start items-center">
              <div
                class="text-xl bg-gray-brand-300 font-bold text-gray-brand-700 w-10 h-10 p-1 flex justify-center items-center rounded-lg"
              >
                {{ numberedIndex('fulfillment') }}
              </div>
              <div class="text-2xl font-bold text-gray-brand-900 tracking-tighter ml-3">
                <span v-if="hasBothFulfillmentOptions">Pickup or delivery</span>
                <span v-else-if="hasPickupOptions">Choose pickup</span>
                <span v-else>Choose delivery</span>
              </div>
            </div>
          </div>

          <!-- selection module -->
          <div class="mt-4">
            <FulfillmentSelectionModule
              v-if="showSelectionFulfillmentModule(bottle)"
              :fulfillment-options-section="fulfillmentSelectionModuleTab"
              :is-loading="fulfillmentIsLoading"
              :passed-bottle="bottle"
              @clicked-new-method="clickedNewMethod"
              @show-loading="fulfillmentShowLoading"
              @delivery-address-edited-and-selected="deliveryAddressEditedAndSelected"
              @delivery-address-action-executed="deliveryAddressActionExecuted"
            />
          </div>
          <!-- selected module -->
          <FulfillmentSelectedModule
            v-if="showSelectedFulfillmentModuleForBottle(bottle)"
            layout="checkout"
            class="mt-4"
            :is-loading="fulfillmentIsLoading"
            @select-new-fulfillment-option="selectNewFulfillmentOption"
            @show-loading="fulfillmentShowLoading"
          />

          <div v-if="isMultibottleFunctionality()">
            <FulfillmentSelectedModule
              v-for="bottle in bottles"
              :key="bottle.id"
              layout="checkout"
              class="mt-4"
              :is-loading="fulfillmentIsLoading"
              :passed-bottle="bottle"
              @select-new-fulfillment-option="selectNewFulfillmentOption"
              @show-loading="fulfillmentShowLoading"
            />
          </div>
        </div>

        <div v-if="requiresMembershipTierSelection" class="px-2 md:px-0 md:w-1/2 mx-auto mt-12 mb-8">
          <div class="flex justify-start items-center">
            <div
              class="text-xl bg-gray-brand-300 font-bold text-gray-brand-700 w-10 h-10 p-1 flex justify-center items-center rounded-lg"
            >
              {{ numberedIndex('membership') }}
            </div>
            <div class="text-2xl font-bold text-gray-brand-900 tracking-tighter ml-3">Membership</div>
          </div>
          <TemplateMembership :show-tier="true" :show-list="true" :show-store="false" :show-autocharge="true" />
        </div>

        <div class="px-2 md:px-0 md:w-1/2 mx-auto mt-12 mb-8">
          <div class="flex justify-start items-center">
            <div
              class="text-xl bg-gray-brand-300 font-bold text-gray-brand-700 w-10 h-10 p-1 flex justify-center items-center rounded-lg"
            >
              {{ numberedIndex('payment') }}
            </div>
            <div
              class="text-2xl font-bold text-gray-brand-900 tracking-tighter ml-3"
              @click="addADifferentPaymentMethod = !addADifferentPaymentMethod"
            >
              Pay
              <span
                v-if="isTestMode"
                class="ml-2 text-xs uppercase bg-brand-100 text-brand-900 px-1 py-1 mr-1 rounded-md shadow-sm font-manrope tracking-widest"
              >
                TEST MODE
              </span>
            </div>
          </div>
          <div
            v-if="!checkout"
            class="mt-4 md:mt-6 bg-white rounded-lg shadow-solid-xs border shadow-gray-brand-600 border-gray-brand-600 p-4"
          >
            Either you're missing a fulfillment slot, the store isn't live, or the order cannot be processed yet.
          </div>
          <PaymentSection
            v-if="checkout || hasLoadedPaymentBlock"
            v-show="checkout"
            :bottle="bottle"
            :checkout="checkout"
            :has-discount-code-box="true"
          />
        </div>
      </div>

      <div class="px-2 md:px-0 md:w-1/2 mx-auto mt-10 mb-8">
        <div class="relative py-5 mb-2">
          <div class="absolute inset-0 flex items-center" aria-hidden="true">
            <div class="w-full border-t border-gray-300"></div>
          </div>
          <div class="relative flex justify-center">
            <span class="bg-gray-brand-100 px-3 text-2xl font-bold text-gray-brand-900 tracking-tighter">
              Order Summary
            </span>
          </div>
        </div>
        <OrderSummarySection :list="$store.getters.cartItems" :checkout-attributes="checkout?.attributes" />
      </div>

      <div v-if="!hasQuestions && hasAnswers" class="px-2 md:px-0 md:w-1/2 mx-auto mt-10 mb-8">
        <div class="relative py-5 mb-2">
          <div class="absolute inset-0 flex items-center" aria-hidden="true">
            <div class="w-full border-t border-gray-300"></div>
          </div>
          <div class="relative flex justify-center">
            <span class="bg-gray-brand-100 px-3 text-2xl font-bold text-gray-brand-900 tracking-tighter">
              Additional Info
            </span>
          </div>
        </div>
        <AnswersSection :bottle="bottle" :answers="answers" />
      </div>
    </div>
  </div>
</template>

<script>
const strftime = require('strftime')

import Loading from '@/components/Molecules/Loading'

import InfoBanner from '@/components/Checkout/InfoBanner'

import FulfillmentSelectionModule from '@/components/Checkout/FulfillmentSelectionModule'
import FulfillmentSelectedModule from '@/components/Checkout/FulfillmentSelectedModule'

import TemplateMembership from '@/components/Checkout/TemplateMembership'

import InfoRow from '@/components/Checkout/InfoRow'
import QuestionRow from '@/components/Checkout/QuestionRow'
import PaymentSection from '@/components/Checkout/PaymentSection'
import TippingSection from '@/components/Checkout/TippingSection'

import OrderSummarySection from '@/components/Checkout/OrderSummarySection'
import AnswersSection from '@/components/Checkout/AnswersSection'

import { MerchantNav } from '@/components'
import ProgressPills from '@/components/Merchant/ProgressPills'

import mapRelationship from '@/utils/mapRelationship'

import SecondaryButton from '@/components/Molecules/SecondaryButton'
export default {
  components: {
    FulfillmentSelectionModule,
    FulfillmentSelectedModule,
    PaymentSection,
    TippingSection,
    InfoRow,
    QuestionRow,
    TemplateMembership,
    OrderSummarySection,
    AnswersSection,
    MerchantNav,
    ProgressPills,
    InfoBanner,
    Loading,
    SecondaryButton
  },
  data: () => ({
    token: null,
    address1: '',
    address2: '',
    city: '',
    state: 'CA',
    zip: '',
    selectNewFulfillmentMethod: false,
    fulfillmentSelectionModuleTab: 'pickup',
    loading: true,
    sections: [],
    fulfillmentIsLoading: false,
    hasLoadedPaymentBlock: false,
    selectedBottle: null
  }),
  computed: {
    computedColors() {
      const hsl = this.$store.getters.info?.attributes.color.hsl
      if (hsl)
        return {
          '--main-brand-hue': hsl[0],
          '--main-brand-saturation': hsl[1],
          '--main-brand-lightness': hsl[2]
        }
    },
    bottle() {
      return this.$store.getters.currentBottle
    },
    bottles() {
      return this.$store.getters.currentBottles.sort((a, b) => (parseInt(a.id) > parseInt(b.id) ? 1 : -1))
    },
    onlyOneMultibottle() {
      return this.bottles.length < 2
    },
    checkout() {
      return this.$store.getters.checkout
    },
    hasQuestions() {
      return this.checkout_questions?.length > 0
    },
    hasAnswers() {
      return this.answers?.length > 0
    },
    isTestMode() {
      return this.checkout?.attributes.mode == 'test'
    },
    requiresFulfillmentSlot() {
      return this.bottle.attributes?.requires_fulfillment_slot
    },
    requiresMembershipTierSelection() {
      return this.bottle.attributes?.requires_membership_tier_selection
    },
    fulfillmentSlotID() {
      return this.bottle?.relationships?.fulfillment_slot?.data?.id
    },
    fulfillmentSlot() {
      return this.$store.getters.fulfillmentSlot
    },
    possibleFulfillmentMethods() {
      return this.$store.getters.fulfillmentMethods(this.bottle?.relationships?.possible_fulfillment_methods?.data)
    },
    hasPickupOptions() {
      // return this.possiblePickupFulfillmentMethods?.length > 0
      return this.bottle?.attributes?.possible_fulfillment_method_types?.pickup?.type == 'pickup'
    },
    hasDeliveryOptions() {
      return this.bottle?.attributes?.possible_fulfillment_method_types?.delivery?.type == 'delivery'
    },
    hasBothFulfillmentOptions() {
      return this.hasPickupOptions && this.hasDeliveryOptions
    },
    showDeliveryFulfillmentOptionsFirst() {
      return mapRelationship(this.$store.state, this.bottle?.relationships?.store?.data)?.attributes
        ?.show_delivery_fulfillment_options_first
    },
    pickupOptions() {
      return this.$store.getters.pickupFulfillmentMethods(this.bottle?.id)
    },
    deliveryOptions() {
      if (this.$store.state.objects.fulfillment_method)
        return Object.values(this.$store.state.objects.fulfillment_method).filter(
          (x) => x?.attributes.type !== 'PickupFulfillmentMethod'
        )
    },
    checkout_questions() {
      return this.$store.getters.questions(this.bottle?.relationships?.checkout_questions?.data)?.slice(0, 1)
    },
    answers() {
      return this.$store.getters.answers(this.bottle?.relationships?.answers?.data)
    },
    progressPills() {
      return [
        ...(this.bottle?.attributes?.supports_selection_screen
          ? [{ status: 'completed', to: { name: 'Selection' }, label: 'Selection' }]
          : []),
        { status: 'current', to: '', label: 'Checkout' }
      ].filter(Boolean)
    }
  },
  watch: {
    fulfillmentSlotID: function (newID, oldID) {
      if (newID !== null) {
        this.selectNewFulfillmentMethod = false
      }
    },
    checkout: function (newCheckout, oldCheckout) {
      if (newCheckout !== null) {
        this.hasLoadedPaymentBlock = true
      }
    }
  },
  async mounted() {
    window.scrollTo(0, 0)
    this.$store.dispatch('getInfo')
    this.$store.dispatch('getMembershipTiers')
    this.$store.dispatch('getCustomerInfo')
    await this.$store.dispatch('getBottle', {
      includes: [
        'fulfillment_slot',
        'fulfillment_slot.fulfillment_method',
        'possible_fulfillment_methods',
        'possible_fulfillment_slot_days',
        'possible_fulfillment_slot_times',
        'possible_delivery_addresses',
        'possible_stripe_payment_methods',
        'possible_fulfillment_methods.delivery_address',
        'possible_fulfillment_methods.fulfillment_slots_detail',
        'checkout',
        'cart',
        'cart.cart_items',
        'cart.cart_items.product',
        'cart.cart_items.product.category_product_store_pairs',
        'cart.applied_discount_codes',
        'checkout_questions',
        'answers',
        'answers.question',
        'possible_tip_amounts'
      ]
    })
    this.loadSections()
    this.loading = false
    this.handleAuthorization()
    this.handleStatus()
    if (this.hasPickupOptions && !this.showDeliveryFulfillmentOptionsFirst) {
      this.fulfillmentSelectionModuleTab = 'pickup'
    } else {
      this.fulfillmentSelectionModuleTab = 'delivery'
    }
  },
  methods: {
    fulfillmentSlotForBottle(bottle) {
      return this.$store.getters.fulfillmentSlotForBottle(bottle)
    },
    showSelectedFulfillmentModuleForBottle(bottle) {
      const slot = mapRelationship(this.$store.state, bottle?.relationships?.fulfillment_slot.data)
      const method = mapRelationship(this.$store.state, slot?.relationships?.fulfillment_method.data)

      if (!slot && !method) {
        return false
      }

      if (method?.attributes?.data?.type === 'PickupFulfillmentMethod') {
        return true
      }

      return !!bottle.relationships.delivery_address.data
    },
    fulfillmentSlotIDForBottle(bottle) {
      return bottle?.relationships?.fulfillment_slot?.data?.id
    },
    showSelectionFulfillmentModule(bottle) {
      if (
        this.selectedBottle?.id === bottle?.id ||
        !bottle.relationships.delivery_address.data ||
        this.selectNewFulfillmentMethod ||
        this.fulfillmentSlotID == null
      ) {
        return true
      } else {
        return false
      }
    },
    fulfillmentSlotForBottle(bottle) {
      return this.$store.getters.fulfillmentSlotForBottle(bottle)
    },
    isMultibottleFunctionality() {
      const currentBottle = this.$store.getters.currentBottle
      if (currentBottle) {
        return currentBottle.attributes.allow_multiple_days && !this.onlyOneMultibottle
      } else {
        return false
      }
    },
    handleAuthorization() {
      if (!this.bottle) {
        this.$router.push({ name: 'Home' })
      } else if (!this.$store.getters.ownsBottle(this.bottle)) {
        this.$router.push({ name: 'Login', params: { bottleid: this.bottle.id } })
      }
    },
    handleStatus() {
      const status = this.$store.getters.currentBottle?.attributes?.status
      if (status == 'paid') {
        this.$router.push({ name: 'Selection' })
      }
    },
    dateForBottle(bottle) {
      if (bottle.attributes.is_bottle_cycle) {
        return this.dateForBottleCycleBottle(bottle)
      } else {
        return this.dateForStoreBottle(bottle)
      }
    },
    dateForBottleCycleBottle(bottle) {
      const bottleDetails = this.bottleCycleDetailsForBottle(bottle)
      return strftime('%A, %B %-d ', new Date(bottleDetails?.fulfillment_slot_day + 'T12:00:00.000Z'))
    },
    dateForStoreBottle(bottle) {
      if (!this.fulfillmentSlotForBottle(bottle)) return undefined
      return strftime(
        '%A, %B %-d ',
        new Date(this.fulfillmentSlotForBottle(bottle).attributes.fulfillment_date + 'T12:00:00.000Z')
      )
    },
    bottleCycleDetailsForBottle(forBottle) {
      return forBottle?.attributes?.bottle_cycle_details
    },
    selectNewFulfillmentOption(option) {
      this.showSelectionOptionForFulfillmentType(option)
    },
    selectNewFulfillmentOptionMultibottle(option) {
      if (this.selectedBottle?.id === option['bottle']?.id) {
        this.selectedBottle = undefined
      } else {
        this.showSelectionOptionForFulfillmentType(option)
      }
    },
    showSelectionOptionForFulfillmentType(option) {
      this.selectedBottle = option['bottle']
      this.selectNewFulfillmentMethod = true
      if (option['method'] == 'pickup') {
        this.fulfillmentSelectionModuleTab = 'pickup'
      } else if (option['method'] == 'address') {
        this.fulfillmentSelectionModuleTab = 'delivery'
      }
    },
    clickedNewMethod() {
      this.selectedBottle = null
      this.selectNewFulfillmentMethod = false
    },
    loadSections() {
      if (this.requiresFulfillmentSlot) {
        this.sections.push('fulfillment')
      }
      if (this.requiresMembershipTierSelection) {
        this.sections.push('membership')
      }
      this.sections.push('payment')
    },
    numberedIndex(sectionString) {
      return this.sections.indexOf(sectionString) + 1
    },
    fulfillmentShowLoading(value) {
      this.fulfillmentIsLoading = value
    },
    deliveryAddressEditedAndSelected() {
      this.selectedBottle = undefined
      this.selectNewFulfillmentMethod = false
    },
    deliveryAddressActionExecuted() {
      this.fulfillmentSelectionModuleTab = 'delivery'
    },
    goToCart() {
      this.$router.push({ name: 'Selection', params: { bottleid: this.bottle?.id }, query: { showCart: 'y' } })
    }
  }
}
</script>
